import Typography from '@/components/typography';

const BannerStoreTest = () => {
  return (
    <div className="sticky bottom-0 flex w-full items-center justify-center bg-warning-3 px-4 py-1">
      <Typography.Span variant="medium-body" className="text-center">
        Essa é uma loja de Teste/Treinamento
      </Typography.Span>
    </div>
  );
};

export default BannerStoreTest;
